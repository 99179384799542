



















































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import { CustomerGroupInfoProperties } from './CustomerGroupInfoView.vue';
import AutoNumeric, { Options } from 'autonumeric';
import CustomerGroupEventService from '@/services/CustomerGroupEventService';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import CommonUtil from '@/utilities/CommonUtil';
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

class CustomerGroupEventDialogProperties {
    isNew: boolean = false;
    visible: boolean = false;
    customerGroupEvent: any = {};
    index: number = -1;
}

export { CustomerGroupEventDialogProperties };

@Component({
    data: () => ({
        statuses: [],
        loading: false
    })
})
export default class CustomerGroupEventDialogView extends Vue {
    @Prop({ default: new CustomerGroupEventDialogProperties() }) 
    private properties: CustomerGroupEventDialogProperties;
    @Prop() private customerGroupInfo: CustomerGroupInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerGroupEventService = new CustomerGroupEventService();
    private inputMinimumTotalAmount: HTMLInputElement;
    private inputMaximumTotalAmount: HTMLInputElement;

    public get customerGroupEvent() {
        return this.properties.customerGroupEvent;
    }

    public get typeOptions() {
        return [
            {
                text: this.$t("text.customer-order"),
                value: "Customer-Order"
            },
            {
                text: this.$t("text.customer-payment"),
                value: "Customer-Payment"
            }
        ];
    }

    public get statusOptions() {
        var status = [];

        if (this.customerGroupEvent.type === "Customer-Order") {
            status = status.concat([
                {
                    text: this.$t("text.to-pay"),
                    value: "To-Pay"
                },
                {
                    text: this.$t("text.checkout"),
                    value: "Checkout"
                },
                {
                    text: this.$t("text.approved"),
                    value: "Approved"
                },
                {
                    text: this.$t("text.paid"),
                    value: "Paid"
                },
                {
                    text: this.$t("text.cancelled"),
                    value: "Cancelled"
                },
                {
                    text: this.$t("text.rejected"),
                    value: "Rejected"
                },
                {
                    text: this.$t("text.expired"),
                    value: "Expired"
                },
                {
                    text: this.$t("text.delivered"),
                    value: "Delivered"
                }
            ]);
        } else if (this.customerGroupEvent.type === "Customer-Payment") {
            status = status.concat([
                {
                    text: this.$t("text.processing"),
                    value: "Processing"
                },
                {
                    text: this.$t("text.approved"),
                    value: "Approved"
                },
                {
                    text: this.$t("text.cancelled"),
                    value: "Cancelled"
                },
                {
                    text: this.$t("text.rejected"),
                    value: "Rejected"
                },
                {
                    text: this.$t("text.refunded"),
                    value: "Refunded"
                },
                {
                    text: this.$t("text.delivered"),
                    value: "Delivered"
                },
                {
                    text: this.$t("text.completed"),
                    value: "Completed"
                }
            ]);
        }

        return status;
    }

    public typeChanged() {
        this.$data.statuses = [];
    }

    public mounted() {
        var dialog = <Vue>this.$refs.content;
        if (dialog) {
            this.inputMinimumTotalAmount = dialog.$el.querySelector("#minimum-total-amount");
            this.inputMaximumTotalAmount = dialog.$el.querySelector("#maximum-total-amount");

            this.updateFormatter();
        }

        var statuses = this.properties.customerGroupEvent.statuses;
        this.$data.statuses = statuses.map(x => x.status);
    }

    public updateFormatter() {
        setTimeout(() => {
            const options: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false
            };

            AutoNumericUtil.findOrNew(this.inputMinimumTotalAmount, null, options)
                .set(this.customerGroupEvent.minimumTotalAmount ?? 0);
            AutoNumericUtil.findOrNew(this.inputMaximumTotalAmount, null, options)
                .set(this.customerGroupEvent.maximumTotalAmount ?? 0);
        }, 150);
    }

    public async submit() {
        if (this.$data.loading) {
            return;
        }
        this.$data.loading = true;

        try {
            var event = CommonUtil.clone(this.customerGroupEvent);
            event.statuses = this.$data.statuses.map(x => { 
                return { 
                    discriminator: this.makeDiscriminator(event),
                    status: x 
                };
            });

            var conflict = this.checkConflict(event);
            if (!conflict) {
                const r = await this.customerGroupEventService.conflict({
                    id: event.id ? event.id : null,
                    type: event.type,
                    statuses: this.$data.statuses,
                    minimumTotalAmount: event.minimumTotalAmount,
                    maximumTotalAmount: event.maximumTotalAmount
                });
                conflict = r.data;
            }

            if (conflict) {
                var m: any = this.$t("message.customer-group-event-conflict");
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            } else {
                const events = this.customerGroupInfo.customerGroupEvents;
                event.minimumTotalAmount = Number(event.minimumTotalAmount ?? 0);
                event.maximumTotalAmount = Number(event.maximumTotalAmount ?? 0);

                if (this.properties.isNew) {
                    events.push(event);
                } else {
                    Vue.set(events, this.properties.index, event);
                }

                await this.customerGroupInfo.events.fire('load-events', false);
                this.properties.visible = false;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m: any = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loading = false;
        }
    }

    public close() {
        if (this.$data.loading) {
            return;
        }

        this.properties.visible = false;
    }

    public makeDiscriminator(event: any) {
        if (event.type === "Customer-Order") {
            return "CustomerOrderEventStatus";
        } else if (event.type === "Customer-Payment") {
            return "CustomerPaymentEventStatus";
        } else {
            return null;
        }
    }

    public checkConflict(event: any) {
        var events = this.customerGroupInfo.customerGroupEvents;
        var index = this.properties.index;
        var conflict = events.find(x => 
            (this.properties.isNew || events.indexOf(x) !== index) &&
            x.discriminator === event.discriminator &&
            x.statuses.some(x => event.statuses.some(y => x.status === y.status)) &&
            x.minimumTotalAmount <= event.minimumTotalAmount &&
            x.maximumTotalAmount >= event.minimumTotalAmount);
        if (!conflict) {
            conflict = events.find(x => 
            (this.properties.isNew || events.indexOf(x) !== index) &&
            x.discriminator === event.discriminator &&
            x.statuses.some(x => event.statuses.some(y => x.status === y.status)) &&
            x.minimumTotalAmount <= event.maximumTotalAmount &&
            x.maximumTotalAmount >= event.maximumTotalAmount);
        }

        return conflict;
    }
}
