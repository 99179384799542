import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerGroupEventService extends AbstractService {
    private controller = "customer-group-events";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public conflict(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/conflict`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }
}