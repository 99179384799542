
















































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import { CustomerGroupEventDialogProperties } from "./CustomerGroupEventDialogView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import CustomerGroupService from "@/services/CustomerGroupService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import EventManager from "@/utilities/EventManager";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const CustomerGroupEventDialogView = () => import("./CustomerGroupEventDialogView.vue");
const CustomerGroupMainView = () => import("./CustomerGroupMainView.vue");
const CustomerGroupEventView = () => import("./CustomerGroupEventView.vue");

class CustomerGroupInfoProperties {
    customerGroup: any = {};
    customerGroupEvents: any[] = [];
    orderExtendExpires: any = null;
    orderExtendExpiresEnabled: boolean = false;
    depositExtendExpires: any = null;
    depositExtendExpiresEnabled: boolean = false;
    checkoutExtendExpires: any = null;
    checkoutExtendExpiresEnabled: boolean = false;
    cancelledExtendExpires: any = null;
    cancelledExtendExpiresEnabled: boolean = false;
    saving: boolean = false;
    events = new EventManager();
}

export { CustomerGroupInfoProperties };

@Component({
    components: {
        EditorControlView,
        AlertDialogView,
        CustomerGroupEventDialogView,
        CustomerGroupMainView,
        CustomerGroupEventView,
    },
    data: () => ({
        tab: null,
    }),
})
export default class CustomerGroupInfoView extends Mixins(InfoViewMixin) {
    private properties = new CustomerGroupInfoProperties();
    private customerGroupEventDialog = new CustomerGroupEventDialogProperties();
    private alertDialog = new AlertDialogProperties();
    private editorControl = new EditorControlProperties();
    private customerGroupService = new CustomerGroupService();

	public get title() {
		const tmp1 = this.$t("title.customer-group");
		const tmp2 = this.$t("title.create");
		const tmp3 = this.$t("title.modify");

		return `${tmp1} - ${this.isCreate() ? tmp2 : tmp3}`;
	}

    public get customerGroup() {
        return this.properties.customerGroup;
    }

    public created() {
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isView()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            this.load();
        }
    }

	public customerLoaded(customerGroup: any) {
		const durationDay = 3600 * 24;

		const orderExtendExpires = customerGroup.orderExtendExpires;
		if (orderExtendExpires !== null) {
			this.properties.orderExtendExpires = Number(orderExtendExpires) / durationDay;
		}
		this.properties.orderExtendExpiresEnabled = orderExtendExpires !== null;

		const depositExtendExpires = customerGroup.depositExtendExpires;
		if (depositExtendExpires !== null) {
			this.properties.depositExtendExpires = Number(depositExtendExpires) / durationDay;
		}
		this.properties.depositExtendExpiresEnabled = depositExtendExpires !== null;

		const checkoutExtendExpires = customerGroup.checkoutExtendExpires;
		if (checkoutExtendExpires !== null) {
			this.properties.checkoutExtendExpires = Number(checkoutExtendExpires) / durationDay;
		}
		this.properties.checkoutExtendExpiresEnabled = checkoutExtendExpires !== null;

		const cancelledExtendExpires = customerGroup.cancelledExtendExpires;
		if (cancelledExtendExpires !== null) {
			this.properties.cancelledExtendExpires = Number(cancelledExtendExpires) / durationDay;
		}
		this.properties.cancelledExtendExpiresEnabled = cancelledExtendExpires !== null;
	}

    public async load() {
        try {
            if (this.isCreate()) {
				const durationDay = 3600 * 24;

                this.properties.customerGroup = {
                    orderExtendExpires: 7 * durationDay, 
                    depositExtendExpires: 30 * durationDay,
                    checkoutExtendExpires: 0,
                    cancelledExtendExpires: 1 * durationDay,
                    orderExtendCount: 0,
                    depositExtendCount: 1,
                    checkoutExtendCount: 0,
                    cancelledExtendCount: 3,
                    purchaseAmountLimit: 0.0,
                    purchaseQuantityLimit: 0,
                    fullNameColor: "#000000",
                    messageColor: "#000000",
                    backgroundColor: "#FFFFFF",
                    tagColor: "#000000",
                };
                this.properties.orderExtendExpiresEnabled = true;
                this.properties.orderExtendExpires = 7;
                this.properties.depositExtendExpiresEnabled = true;
                this.properties.depositExtendExpires = 30;
                this.properties.checkoutExtendExpiresEnabled = false;
                this.properties.checkoutExtendExpires = 0;
                this.properties.cancelledExtendExpiresEnabled = true;
                this.properties.cancelledExtendExpires = 1;
            } else if (this.isModify()) {
                var id = this.$route.query.id;
                const r = await this.customerGroupService.get({ id, hidden: false });
                if (r.data.customerGroups.length === 0) {
                    throw new Error(this.$t("message.record-not-found").toString());
                }

                var customerGroup = r.data.customerGroups[0];
                if (!customerGroup.fullNameColor) {
                    customerGroup.fullNameColor = "#000000";
                }
                if (!customerGroup.messageColor) {
                    customerGroup.messageColor = "#000000";
                }
                if (!customerGroup.backgroundColor) {
                    customerGroup.backgroundColor = "#FFFFFF";
                }
                if (!customerGroup.tagColor) {
                    customerGroup.tagColor = "#000000";
                }

				this.customerLoaded(customerGroup);
                this.properties.customerGroup = customerGroup;
            }

            await this.properties.events.fire("update-formatter");
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async create() {
        await this.$router.push("/management/customer/customer-groups/create");
        this.mode = "create";
        await this.load();
    }

    public async save() {
        if (this.properties.saving) {
            return;
        }

        this.properties.saving = true;
        try {
            var customerGroup = this.properties.customerGroup;
            var customerGroupEvents = this.properties.customerGroupEvents;

			const durationDay = 3600 * 24;

			if (this.properties.orderExtendExpiresEnabled && this.properties.orderExtendExpires !== null) {
				customerGroup.orderExtendExpires = this.properties.orderExtendExpires * durationDay;
			} else {
				customerGroup.orderExtendExpires = null;
			}
			if (this.properties.depositExtendExpiresEnabled && this.properties.depositExtendExpires !== null) {
				customerGroup.depositExtendExpires = this.properties.depositExtendExpires * durationDay;
			} else {
				customerGroup.depositExtendExpires = null;
			}
			if (this.properties.checkoutExtendExpiresEnabled && this.properties.checkoutExtendExpires !== null) {
				customerGroup.checkoutExtendExpires = this.properties.checkoutExtendExpires * durationDay;
			} else {
				customerGroup.checkoutExtendExpires = null;
			}
			if (this.properties.cancelledExtendExpiresEnabled && this.properties.cancelledExtendExpires !== null) {
				customerGroup.cancelledExtendExpires = this.properties.cancelledExtendExpires * durationDay;
			} else {
				customerGroup.cancelledExtendExpires = null;
			}

            if (this.isCreate()) {
                const r = await this.customerGroupService.post(customerGroup, customerGroupEvents);

				this.customerLoaded(r.data);
                this.properties.customerGroup = r.data;
                await this.$router.push(
                    "/management/customer/customer-groups/modify?id=" +
                        this.properties.customerGroup.id
                );
                this.mode = "modify";

                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            } else if (this.isModify()) {
                const r = await this.customerGroupService.put(customerGroup, customerGroupEvents);

				this.customerLoaded(r.data);
                this.properties.customerGroup = r.data;
                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            }
            await this.properties.events.fire('load-events');
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public cancel() {
        if (this.properties.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public async remove() {
        if (this.properties.saving) {
            return;
        }
        this.properties.saving = true;

        try {
            await this.customerGroupService.delete(this.properties.customerGroup);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.properties.saving = false;
        }
    }

    public removeDialog() {
        AlertDialogProperties.delete(this.alertDialog, null);
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async control(button: string) {
        if (button === "create") {
            await this.create();
        } else if (button === "save") {
            await this.save();
        } else if (button === "cancel") {
            this.cancel();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "discard-changes" && button === this.$t("text.yes")) {
                await this.load();
            } else if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            }
        }
        await this.properties.events.fire('alert-dialog-clicked', button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            if (tag.command === "invalid-mode" || tag.command === "load-error" || tag.command === "record-deleted") {
                await this.$router.push("/management/customer/customer-groups");
            }
        }
    }
}
